<template>
    <div v-if="user" class="profile__detail register">
        <div>
            <div class="stepper">
                <div class="stepper__icon stepper__icon__down">
                    <img v-on:click="changeHeight(-1)" src="@/assets/ic_32_stepper_down.svg" alt=""/>
                </div>
                <div class="stepper__value">
                    {{ height }} cm
                </div>
                <div class="stepper__icon stepper__icon__up">
                    <img v-on:click="changeHeight(1)" src="@/assets/ic_32_stepper_up.svg" alt=""/>
                </div>
            </div>

            <div class="stepper">
                <div class="stepper__icon stepper__icon__down">
                    <img v-on:click="changeWeight(-1)" src="@/assets/ic_32_stepper_down.svg" alt=""/>
                </div>
                <div class="stepper__value">
                    {{ weight }} kg
                </div>
                <div class="stepper__icon stepper__icon__up">
                    <img v-on:click="changeWeight(1)" src="@/assets/ic_32_stepper_up.svg" alt=""/>
                </div>
            </div>
        </div>

        <div class="selection">
            <div class="selection__item">
                <input id="gender-0" name="gender-0" type="radio" v-model="gender" value="0">
                <label for="gender-0">
                    <img class="selection__item__icon" src="@/assets/ic_32_gender_man.svg" alt=""/>
                    <img class="selection__item__icon selection__item__icon--active" src="@/assets/ic_32_gender_man-active.svg" alt=""/>
                    <div class="selection__item__label">Männlich</div>
                </label>
            </div>
            <div class="selection__item">
                <input id="gender-1" name="gender-1" type="radio" v-model="gender" value="1">
                <label for="gender-1">
                    <img class="selection__item__icon" src="@/assets/ic_32_gender_woman.svg" alt=""/>
                    <img class="selection__item__icon selection__item__icon--active" src="@/assets/ic_32_gender_woman-active.svg" alt=""/>
                    <div class="selection__item__label">Weiblich</div>
                </label>
            </div>
            <div class="selection__item">
                <input id="gender-2" name="gender-2" type="radio" v-model="gender" value="2">
                <label for="gender-2">
                    <img class="selection__item__icon" src="@/assets/ic_32_gender_divers.svg" alt=""/>
                    <img class="selection__item__icon selection__item__icon--active" src="@/assets/ic_32_gender_divers-active.svg" alt=""/>
                    <div class="selection__item__label">Divers</div>
                </label>
            </div>
        </div>

        <div class="date-input">
            <img class="selection__item__icon" src="@/assets/ic_32_date.svg" alt=""/>

            <input type="date" ref="birthdayInput" v-model="birthday" placeholder="Geburtstag (optional)" :min="minDate" :max="maxDate" onchange="this.className=(this.value!=''?'has-value':'')">
        </div>

        <v-btn class="button button--primary" @click="saveSettings">Übernehmen</v-btn>
    </div>
</template>
<script>
import firebase from 'firebase';

export default {
    name: 'SettingsPersonal',
    components: { },
    data() {
        return {
            user: null,
            userInformation: null,
            height: 175,
            weight: 75,
            gender: null,
            birthday: null,
            maxDate: new Date().toISOString().split('T')[0],
            minDate: "1900-01-01",
        }
    },
    methods: {
        init(){
            this.user = firebase.auth().currentUser;

            this.$store.dispatch('fetchUserInformation').then( (res) => {
                if (res.length == 0) {
                    this.userInformation = this.$store.getters.getOnboardingData;
                    if (typeof(this.userInformation.birthday) == "string") {
                        this.birthday = this.userInformation.birthday.split('T')[0];
                    }
                    else {
                        this.birthday = this.userInformation.birthday.toISOString();
                    }
                }
                else {
                    this.userInformation = res[0];
                    if (this.userInformation.birthday) {
                        let birthday = new Date(0);
                        birthday.setUTCSeconds(this.userInformation.birthday.seconds);
                        this.birthday = birthday.toISOString().split('T')[0];
                    }
                }
                this.height = this.userInformation.height || 175;
                this.weight = this.userInformation.weight || 75;
                this.gender = this.userInformation.gender;

                if (this.birthday && this.birthday.length > 0) {
                    this.$refs.birthdayInput.classList.add("has-value");
                }
            });
        },
        changeHeight(val) { this.height+=val },
        changeWeight(val) { this.weight+=val },
        saveSettings() {
            this.userInformation.height = this.height;
            this.userInformation.weight = this.weight;
            let user_birthday = null
            if (this.birthday != null && this.birthday != "") {
                user_birthday = new Date(this.birthday);
                user_birthday.setSeconds(0);
                user_birthday.setMinutes(0);
                user_birthday.setHours(12);
            }
            this.userInformation.birthday = user_birthday;
            this.userInformation.gender = this.gender;
            this.$store.dispatch('saveUserInformation', this.userInformation);
            this.$router.go(-1);
        }
    },
    mounted() {
        this.init();
    }
}
</script>

<style lang="scss">
</style>